import { Application } from '@hotwired/stimulus'

const application = Application.start()

// Not sure why but I had to do my own glob import here, instead of using the one from the stimulus-vite-helpers
const controllers = import.meta.glob('./**/*_controller.js');

// Function to register the controllers
async function registerControllerModified(app, controllers) {
    for (const path in controllers) {
        const module = await controllers[path]();
        const controllerName = path
            .split('/')
            .pop()
            .replace('_controller.js', '')
            .replace(/_/g, '-');
        app.register(controllerName, module.default);
    }
}

registerControllerModified(application, controllers);